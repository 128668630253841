import { Avatar, Box, Text, VStack } from "@chakra-ui/react";
import ajay from "./ajay.jpeg";
import kian from "./kian.jpeg";

function Founder({ name, linkedinUrl }) {
  const image = name === "ajay" ? ajay : kian;

  return (
    <a href={linkedinUrl}>
      <Box padding={10}>
        <VStack>
          <Avatar size="2xl" src={image}></Avatar>
          <Text fontSize="2xl">{name}</Text>
        </VStack>
      </Box>
    </a>
  );
}

export default Founder;
